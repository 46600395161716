/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"
import gh from "../images/gh.png"
import Form from "../components/form"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Menu width="500px" right>
          <Link style={{ color: "white", zIndex: 1 }} to="/">
            Home
          </Link>
          <Link style={{ color: "white", zIndex: 1 }} to="/projects">
            Projects
          </Link>
          <div className="menu-contact-container">
            <div className="menu-contact-container-form">
              <h2>Let's chat.</h2>
              <Form buttonText="Send it!" />
            </div>
          </div>
          <a
            className="github-link"
            href="https://github.com/smallbrownbike"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Eli Kinsey's Github Page" src={gh} />
          </a>
          <div className="menu-shape"></div>
        </Menu>
        <main>{children}</main>
        <footer></footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
