import React from "react"

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        name: {
          value: "",
          error: false,
          errorMessage: "",
        },
        phone: {
          value: "",
          error: false,
          errorMessage: "",
        },
        email: {
          value: "",
          error: false,
          errorMessage: "",
        },
        message: {
          value: "",
          error: false,
          errorMessage: "",
        },
      },
    }
  }
  handleInputChange = (inputName, e) => {
    const { form } = this.state
    form[inputName].value = e.target.value
    this.setState({ form })
  }

  handleFormSubmit = e => {
    const getErrorMessage = num => {
      let message = ""
      switch (num) {
        case 1:
          message = "Please fill out this field"
          break
        case 2:
          message = "And this one"
          break
        case 3:
          message = "And this one too"
          break
        case 4:
          message = "One more..."
          break
        default:
          message = "Please fill out this field"
      }
      return message
    }
    const { form } = this.state
    const formKeys = Object.keys(form)
    let errorCount = 0
    formKeys.forEach(key => {
      if (!form[key].value.trim()) {
        errorCount += 1
        form[key].error = true
        form[key].errorMessage = getErrorMessage(errorCount)
      } else {
        form[key].error = false
      }
    })
    if (formKeys.some(key => form[key].error)) {
      e.preventDefault()
      this.setState({ form })
    }
  }
  render() {
    const { form } = this.state
    return (
      <form
        onSubmit={this.handleFormSubmit}
        method="POST"
        netlify-honeypot="bot-field"
        className="contact-form"
        name="contact"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact" />
        <label style={!form.name.error ? { display: "none" } : {}} for="name">
          {form.name.errorMessage}
        </label>
        <input
          onChange={e => this.handleInputChange("name", e)}
          placeholder="your name"
          type="text"
          name="name"
        />
        <label style={!form.phone.error ? { display: "none" } : {}} for="phone">
          {form.phone.errorMessage}
        </label>
        <input
          onChange={e => this.handleInputChange("phone", e)}
          placeholder="your number"
          type="tel"
          name="phone"
        />
        <label style={!form.email.error ? { display: "none" } : {}} for="email">
          {form.email.errorMessage}
        </label>
        <input
          onChange={e => this.handleInputChange("email", e)}
          placeholder="your email"
          type="email"
          name="email"
        />
        <label
          style={!form.message.error ? { display: "none" } : {}}
          for="message"
        >
          {form.message.errorMessage}
        </label>
        <textarea
          onChange={e => this.handleInputChange("message", e)}
          placeholder="your thoughts"
          name="message"
        ></textarea>
        <p style={{ display: "none" }}>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <button type="submit">{this.props.buttonText}</button>
      </form>
    )
  }
}
